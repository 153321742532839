import { zodResolver } from '@hookform/resolvers/zod'
import { Loader2, XIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSWRConfig } from 'swr'
import * as z from 'zod'

import appleStoreImage from '@/assets/images/app-store.webp'
import googleStoreImage from '@/assets/images/google-store.webp'
import qrCode from '@/assets/images/qr-code.svg'
import { subscribeReferee } from '@/lib/api'
import { cn } from '@/lib/utils'

const emailSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Must be a valid email',
  }),
  referralCode: z.string().min(1, { message: 'Referral code is missing' }),
})

type EmailSchema = z.infer<typeof emailSchema>

interface Props {
  links: {
    googleStore: string
    appleStore: string
  }
  translations: {
    download: string
    subtitle: string
    register: string
    title: string
  }
}

export const ReferralForm = ({ links, translations }: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { mutate } = useSWRConfig()
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<EmailSchema>({
    resolver: zodResolver(emailSchema),
  })

  const search = import.meta.env.SSR ? '' : window.location.search
  const hostname = import.meta.env.SSR ? '' : window.location.hostname

  const isProd = hostname.includes('.com')

  const params = new URLSearchParams(search)
  const referralCode = params.get('referralCode')

  const onSubmit = async (data: EmailSchema) => {
    try {
      await mutate('subscribeReferee', () =>
        subscribeReferee({ ...data, isProd }),
      )

      setShowSuccessModal(true)
      reset()
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError('email', {
          type: 'custom',
          message: e.message,
        })
      }
    }
  }

  useEffect(() => {
    if (showSuccessModal) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  })

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        <div className="flex bg-white rounded-2xl py-3 md:px-8 px-4 gap-3">
          <input
            {...register('email')}
            className="bg-transparent tracking-tight text-xl font-semibold outline-none"
            type="email"
            name="email"
            placeholder="Email"
          />

          <input
            type="hidden"
            {...register('referralCode')}
            defaultValue={referralCode ?? undefined}
          />
        </div>

        {errors.email && (
          <p className="text-red-500 text-base">{errors.email.message}</p>
        )}
        {errors.referralCode && (
          <p className="text-red-500 text-base">
            {errors.referralCode.message}
          </p>
        )}

        <button
          disabled={isSubmitting}
          className="flex items-center justify-center gap-3 bg-primary hover:bg-primary-dark font-bold text-xl text-white py-3 w-full rounded-xl transition-colors"
        >
          {translations.register}
          {isSubmitting && (
            <Loader2 className="animate-spin text-white w-5 h-5" />
          )}
        </button>
      </form>

      <div
        className={cn(
          'fixed inset-0 bg-neutral-gray-1000 bg-opacity-50 overflow-y-auto h-full w-full',
          {
            hidden: !showSuccessModal,
          },
        )}
      >
        <div
          className="relative top-1/2 transform -translate-y-1/2
        mx-auto border max-w-3xl shadow-lg rounded-2xl bg-white flex justify-center flex-col items-center p-12"
        >
          <button
            className="absolute top-5 right-5"
            onClick={() => setShowSuccessModal(false)}
          >
            <XIcon className="w-6 h-6" />
          </button>
          <h2 className="text-2xl md:text-5xl font-bold">
            {translations.title}
          </h2>
          <div className="p-3"></div>
          <p className="text-2xl">{translations.subtitle}</p>

          <div className="p-3"></div>

          <div className="bg-white p-5 w-40 md:w-80 h-40 md:h-80 rounded-xl">
            <img
              src={qrCode.src}
              alt="Download QR code"
              className="w-32 md:w-72 h-32 md:h-72"
            />
          </div>

          <div className="p-3"></div>

          <div className="flex gap-4">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={links.googleStore}
            >
              <img
                src={googleStoreImage.src}
                alt="Google Play store"
                className="w-40 h-12"
              />
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={links.appleStore}
            >
              <img
                src={appleStoreImage.src}
                alt="Apple Store"
                className="w-40 h-12"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
